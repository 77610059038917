import dayjs from 'dayjs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import Select from 'react-select'
import Calendar from 'react-datepicker'
import Progress from '../../components/loaders/Progress'
import CSVDownloader from '../../components/CSVExporter/CSVDownloader'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Permission, Salutation, Title, User } from '../../types'
import * as userRoles from '../../constants/userRoles'
import { Formik } from 'formik'
import { bool, date, object, ref, string } from 'yup'
import { editableRoles, rolesObject } from '../../constants/userRoles'
import {
  createUser,
  deleteUserById,
  getAllUsers,
  resetUserMessage,
  updateUserActivationById,
  updateUserById,
  updateUserCompanyById,
  updateUserEmailVerificationById,
  adminUpdateUserPasswordById,
  resetUserReset,
  getAllPagesForUsers,
  resetUserError
} from '../../store/reducers/api/usersReducer'
import { countries } from '../../utils/countries'
import AddressEditor from '../../components/editors/AddressEditor'
import { getAllSalutations } from '../../store/reducers/api/salutationReducer'
import { getAllTitles } from '../../store/reducers/api/titleReducer'
import { getCompanies } from '../../store/reducers/api/companyReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { errorStatusCodes } from '../../constants/statusCodes'
import Pagination from '../../components/Pagination'
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal'
import { dismissModal } from '../../utils/dismissModal'
import useDebounce from '../../utils/hooks/useDebounce'
import hasPermission from '../../utils/checkPermissions'
import * as appModules from '../../constants/appModules'
import { READ } from '../../constants/permissions'
import { ActionIcon } from '../../components/icons/ActionIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { PencilIcon } from '../../components/icons/PencilIcon'
import ConfirmationModalBox from '../../components/ConfirmationModalBox'
import { useNavigate } from 'react-router-dom'
import ModalBox from '../../components/ModalBox'
import { getMappedUserData } from '../../utils/getNormalizedExportData'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'

const AllUsers = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const users = useAppSelector((state) => state.apiUsers.users)
  const user = useAppSelector((state) => state.apiUsers.user)
  const userReset = useAppSelector((state) => state.apiUsers.userReset)
  const message = useAppSelector((state) => state.apiUsers.message)
  const metadata = useAppSelector((state) => state.apiUsers.metadata)
  const isLoading = useAppSelector((state) => state.apiUsers.isLoading)
  const isUpdating = useAppSelector((state) => state.apiUsers.isUpdating)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const error = useAppSelector((state) => state.apiUsers.error)
  const companies = useAppSelector((state) => state.apiCompany.companies)
  const isLoadingCompanies = useAppSelector((state) => state.apiCompany.isLoading)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [exportType, setExportType] = useState<'allPages' | 'currentPage'>('currentPage')
  const [exportData, setExportData] = useState<Array<any>>([])
  const [initialUser, setInitialUser] = useState<Partial<User>>({
    email: '',
    role: userRoles.USER,
    isVerified: false,
    birthDate: null,
    hireDate: null,
    releaseDate: null,
    startDate: null
  })
  const [sendEmail, setSendEmail] = useState(true)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []
  const userEditableRoles = [
    ...editableRoles,
    { value: 'Administrator', text: 'Administrator' }
  ]
  const token = currentUser?.token
  const role = profile?.role || userRoles.USER
  const [filter, setFilter] = useState<string | undefined>('')
  const isAllowed = (permission: Permission = READ) => {
    return hasPermission(appModules.USERS, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentDate = dayjs()
  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const userEmailVerificationSchema = object({
    isVerified: bool()
      .required('Verification Status is required')
  })

  const userActivationSchema = object({
    isActive: bool()
      .required('Active Status is required')
  })

  const userSchema = object({
    salutation: string(),
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long'),
    username: string()
      .nullable()
      .min(2, 'Username is too short')
      .max(32, 'Username is too long')
      .matches(/^\S+$/, 'Enter a valid username'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().oneOf(countries),
    birthDate: date().label('Birth Date').nullable(),
    hireDate: date().label('Hire Date').nullable(),
    releaseDate: date().label('Release Date').nullable(),
    startDate: date().label('Start Date').nullable()
  })

  const adminUserCreateSchema = object({
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name'),
    email: string().email('Enter a valid email').required('Email is required'),
    password: string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: string()
      .required('Confirm Password is required')
      .oneOf([ref('password'), null], 'Passwords must match'),
    role: string()
      .required('Role is required')
      .oneOf([...editableRoles.map((role) => role.value), userRoles.ADMIN]),
    companyId: string().nullable(),
    isGhost: bool()
  })

  const userCompanySchema = object({
    companyId: string().nullable(),
    role: string().label('Role')
      .when('companyId', {
        is: (companyId: string) => (companyId === null || companyId === '' || companyId === undefined),
        then: string().when('role', {
          is: (role: string) => role !== userRoles.ADMIN,
          then: string().oneOf([userRoles.USER]),
          otherwise: string().oneOf([...editableRoles.map((role) => role.value), userRoles.ADMIN])
        }),
        otherwise: string().oneOf([...editableRoles.map((role) => role.value), userRoles.ADMIN])
      }).required('Role is required')
  })

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const updateUserCompanyAndRole = (
    userId: string,
    token: string,
    user: any,
    signal: AbortSignal
  ) => {
    dispatch(updateUserCompanyById({ id: userId, token, user, signal }))
  }

  const updateUserEmailVerification = (
    userId: string,
    token: string,
    user: any,
    signal: AbortSignal
  ) => {
    dispatch(updateUserEmailVerificationById({ id: userId, token, user, signal }))
  }

  const updateUserActivation = (
    userId: string,
    token: string,
    user: any,
    signal: AbortSignal
  ) => {
    dispatch(updateUserActivationById({ id: userId, token, user, signal }))
  }

  const handleUsersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && isAllowed()) {
      const search = debouncedSearchTerm || undefined
      dispatch(getAllUsers({ token, perPage, page, signal, search, filter }))
    }
  }

  const updateUserPasswordById = (sendEmail: boolean) => {
    const controller = new AbortController()
    const signal = controller.signal

    const user = {
      sendEmail
    }
    token && initialUser.id && dispatch(adminUpdateUserPasswordById({ token, id: initialUser.id, user, signal }))
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllTitles({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    token && dispatch(getCompanies({ token, perPage: 100, page: 1, signal }))
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && isAllowed()) {
      const search = debouncedSearchTerm || undefined
      dispatch(getAllUsers({ token, perPage, page, signal, search, filter }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm, filter])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((user && message) && token && isAllowed()) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetUserMessage())
      dismissModal('userModal')
      dispatch(getAllUsers({ token, perPage, page, signal, filter }))
    }

    return () => {
      controller.abort()
    }
  }, [user, token])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && message && isAllowed()) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getAllUsers({ token, perPage, page, signal, filter }))
      dismissModal('editRoleOrCompany')
      dismissModal('updateUserVerification')
      dismissModal('updateUserActiveState')
      dismissModal('editUserModal')
      dismissModal('confirmationModal')
      dismissModal('editUserAddressModal')
      dispatch(resetUserMessage())
    }
  }, [message])

  useEffect(() => {
    if (error && error.errors) {
      const payload = {
        title: errorStatusCodes[error.statusCode],
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetUserError())
    }
  }, [error])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5">
                <i className="bi bi-people me-1"></i> Users
              </p>
              {isAllowed() && (
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: ''
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTerm(search)
                        if (page !== 1) {
                          setPage(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTerm(search)
                                if (page !== 1) {
                                  setPage(1)
                                  navigate('')
                                }
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search && touched.search && errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearch"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearch"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-dark "
                    data-bs-toggle="collapse"
                    data-bs-target="#filterOptions"
                    aria-expanded="false"
                    aria-controls="filterOptions"
                    title="Filter"
                    aria-label="Filter"
                  >
                    <i className="fas fa-filter"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary ms-2 ms-md-2"
                    data-bs-toggle="modal"
                    data-bs-target="#userModal"
                    title="Add User"
                    aria-label="Add User"
                    onClick={() => {
                      setInitialUser({
                        email: '',
                        isVerified: false,
                        role: userRoles.USER
                      })
                    }}
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-2 d-none d-md-inline-block">
                      Add User
                    </span>
                  </button>
                  <button
                    type="button"
                    title="Export to CSV"
                    data-bs-toggle="modal"
                    data-bs-target="#exportToCSV"
                    onClick={() => {
                      setExportType('currentPage')
                      setExportData(getMappedUserData(users, 'all'))
                    }}
                    aria-label="Export to CSV"
                    className="btn btn-outline-dark ms-2 ms-md-2"
                  >
                    <i className="bi bi-filetype-csv"></i>
                  </button>
                  <button
                    type="button"
                    title="Refresh"
                    aria-label="Refresh"
                    className="btn btn-outline-dark ms-2 ms-md-2"
                    onClick={() => handleUsersRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              )}
            </div>
            <div className="accordion" id="filterAccordion">
              <div
                id="filterOptions"
                className="accordion-collapse collapse"
                data-bs-parent="#filterAccordion"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          htmlFor="filterByRole"
                          className="form-label"
                        >
                          User Role
                        </label>
                        <select
                          aria-label="User Role"
                          onChange={(event) => {
                            event.target.value ? setFilter(`filter[role]=${event.target.value}`) : setFilter('')
                          }}
                          className={'form-select'}
                          id="filterByRole"
                          name="role"
                        >
                          <option value={''}>None</option>
                          {
                            userEditableRoles.map((role) => (<option key={role.text} value={role.value}>{role.text}</option>))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading
              ? (<Progress marginTop={true} />)
              : (<hr className="border border-primary border-1 opacity-50"></hr>)
            }
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Company</th>
                    <th scope="col">Email</th>
                    <th scope="col" className="text-nowrap">
                      Date Created
                    </th>
                    <th scope="col">Role</th>
                    <th scope="col">Verified</th>
                    <th scope="col">Active</th>
                    <th scope="col" className="text-end">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? (
                        Array.from(Array(10).keys()).map((n: number) => (
                          <SkeletonTableRow
                            key={n}
                            colSpan={9}
                            actionQuantity={1}
                          />
                        ))
                      )
                    : users.length > 0
                      ? (
                          users.map((user: User) => (
                            <tr
                              key={user.id}
                              className={`${user.isGhost ? 'text-muted' : ''} ${
                                initialUser.id === user.id ? 'table-primary' : ''
                              }`}
                            >
                              <td
                                className="user-select-all"
                                onClick={() =>
                                  navigator?.clipboard.writeText(String(user.id))
                                }
                              >
                                {String(user.id).substring(0, 8)}
                              </td>
                              <td>{`${user.firstName} ${user.lastName}`}</td>
                              <td>{user.company?.name || '-'}</td>
                              <td>{user.email}</td>
                              <td className="text-nowrap">
                                {dayjs(user.createdAt).format('ll')}
                              </td>
                              <td>
                                {user.isGhost && (
                                  <span className={'badge text-bg-secondary me-1'}>
                                    Ghost
                                  </span>
                                )}
                                <span className={'badge text-bg-dark'}>
                                  {rolesObject[user.role]}
                                </span>
                              </td>
                              <td className="text-center">
                                <span>
                                  {user.isVerified
                                    ? (<i className="bi bi-check-circle-fill text-success"></i>
                                      )
                                    : (<i className="bi bi-x-circle-fill text-danger"></i>)
                                  }
                                </span>
                              </td>
                              <td className="text-center">
                                <span>
                                  {user.isActive
                                    ? (<i className="bi bi-check-circle-fill text-success"></i>)
                                    : (<i className="bi bi-x-circle-fill text-danger"></i>)
                                    }
                                </span>
                              </td>
                              <td className="text-end">
                                <div className="dropdown d-flex flex-row float-end">
                                  <button
                                    className="btn btn-outline-dark btn-round"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                    onClick={() => setInitialUser(user)}
                                  >
                                    <ActionIcon />
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <button
                                        className="btn dropdown-item"
                                        type="button"
                                        title="Edit Role or Company"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editRoleOrCompany"
                                        onClick={() => {
                                          setInitialUser(user)
                                        }}
                                        disabled={false}
                                      >
                                        <i className="bi bi-person-badge me-2"></i>
                                        Edit Role or Company
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="btn dropdown-item"
                                        type="button"
                                        title="Edit User Data"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editUserModal"
                                        onClick={() => {
                                          setInitialUser(user)
                                        }}
                                        disabled={false}
                                      >
                                        <div className="d-flex align-items-center">
                                          <PencilIcon />
                                          <span className="ms-2">Edit User Data</span>
                                        </div>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="btn dropdown-item"
                                        type="button"
                                        title="Edit Address Data"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editUserAddressModal"
                                        onClick={() => {
                                          setInitialUser(user)
                                        }}
                                        disabled={false}
                                      >
                                        <div className="d-flex align-items-center">
                                          <i className="bi bi-geo-alt"></i>
                                          <span className="ms-2">Edit Address Data</span>
                                        </div>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="btn dropdown-item"
                                        type="button"
                                        title="Reset User Password"
                                        data-bs-toggle="modal"
                                        data-bs-target="#adminResetPassword"
                                        onClick={() => {
                                          setInitialUser(user)
                                          dispatch(resetUserReset())
                                        }}
                                        disabled={false}
                                      >
                                        <i className="bi bi-key"></i>
                                        <span className="ms-2">Reset Password</span>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="btn dropdown-item"
                                        type="button"
                                        title={`${
                                          user.isVerified ? 'Unverify' : 'Verify'
                                        } User`}
                                        data-bs-toggle="modal"
                                        data-bs-target="#updateUserVerification"
                                        onClick={() => {
                                          setInitialUser(user)
                                        }}
                                      >
                                        <i
                                          className={`bi ${
                                            user.isVerified
                                              ? 'bi-person-x'
                                              : 'bi-person-check'
                                          } me-2`}
                                        ></i>
                                        {`${
                                          user.isVerified ? 'Unverify' : 'Verify'
                                        } User`}
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="btn dropdown-item"
                                        type="button"
                                        title={`${
                                          user.isActive ? 'Deactivate' : 'Activate'
                                        } User`}
                                        data-bs-toggle="modal"
                                        data-bs-target="#updateUserActiveState"
                                        onClick={() => {
                                          setInitialUser(user)
                                        }}
                                      >
                                        <i
                                          className={`bi ${
                                            user.isActive ? 'bi-lock' : 'bi-unlock'
                                          } me-2`}
                                        ></i>
                                        {`${
                                          user.isActive ? 'Deactivate' : 'Activate'
                                        } User`}
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="btn dropdown-item text-danger"
                                        type="button"
                                        title="Delete User"
                                        data-bs-toggle="modal"
                                        data-bs-target="#confirmationModal"
                                        onClick={() => {
                                          setInitialUser(user)
                                        }}
                                        disabled={
                                          user.id === user?.company?.owner?.id
                                        }
                                      >
                                        <div className="d-flex align-items-center">
                                          <TrashIcon height={16} width={16} />
                                          <span className="ms-2">Delete User</span>
                                        </div>
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        )
                      : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            No users available yet
                          </td>
                        </tr>
                        )}
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: (metadata.page - 1) * metadata.perPage
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              module="Users"
              isTrackingPage
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editRoleOrCompany"
        tabIndex={-1}
        aria-labelledby="editRoleOrCompanyLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="editRoleOrCompanyLabel">
                <i className="bi bi-pencil-square me-2"></i>Edit Employee Role
                or Company
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              <p>
                <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                with email<b>{` '${initialUser.email}'`}</b>
              </p>
              <Formik
                validationSchema={userCompanySchema}
                enableReinitialize
                initialValues={{
                  companyId: initialUser?.company?.id || '',
                  role: initialUser?.role || userRoles.USER
                }}
                onSubmit={({ companyId, role }, actions) => {
                  const controller = new AbortController()
                  const signal = controller.signal
                  const user = {
                    companyId: companyId || null,
                    role
                  }
                  const userId = initialUser?.id
                  if (token && userId) {
                    updateUserCompanyAndRole(userId, token, user, signal)
                  }
                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="userRole" className="form-label">
                            User Role
                          </label>
                          <select
                            aria-label="User Role"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.role}
                            className={`form-select ${
                              errors.role && touched.role ? 'is-invalid' : ''
                            }`}
                            id="userRole"
                            name="role"
                            disabled={initialUser?.id === profile?.id}
                          >
                            <option value="">Select Role</option>
                            <option value={userRoles.ADMIN}>{userRoles.ADMIN}</option>
                            {editableRoles.map((role) => (
                              <option key={role.value} value={role.value}>
                                {role.text}
                              </option>
                            ))}
                          </select>
                          <div id="validationUserRoleFeedback" className="invalid-feedback">
                            {errors.role}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="editCompany" className="form-label">
                            Company
                          </label>
                          <Select
                            className={`${
                              errors.companyId && touched.companyId && errors.companyId
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  errors.companyId && touched.companyId
                                    ? '#dc3545'
                                    : provided.borderColor,
                                '&:hover': {
                                  borderColor:
                                    errors.companyId && touched.companyId
                                      ? '#dc3545'
                                      : provided.borderColor
                                }
                              })
                            }}
                            isClearable
                            inputId="editCompany"
                            name="companyId"
                            aria-label="Company"
                            options={companies}
                            getOptionLabel={(company) =>
                              `${company.name} - ${company.domain || 'Domain not set'}`
                            }
                            getOptionValue={(company) => String(company.id)}
                            onChange={(selectedOption) => {
                              const selectedCompanyId = selectedOption?.id ?? ''
                              setFieldValue('companyId', selectedCompanyId)
                            }}
                            onBlur={handleBlur}
                            isLoading={isLoadingCompanies}
                            value={companies.find((company) => company.id === values.companyId)}
                          />
                          <div id="validationCompanyFeedback" className="invalid-feedback">
                            {errors.companyId}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary mt-2"
                        disabled={isSubmitting || isLoading}
                      >
                        <i className="bi bi-save"></i> Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="updateUserVerification"
        tabIndex={-1}
        aria-labelledby="updateUserVerificationLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="updateUserVerificationLabel">
                <i className="bi bi-pencil-square me-2"></i>Update User
                Verification Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              <p>
                Update the verification status for{' '}
                <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                with email<b>{` '${initialUser.email}'`}</b>
              </p>
              <Formik
                validationSchema={userEmailVerificationSchema}
                enableReinitialize
                initialValues={{
                  isVerified: initialUser.isVerified || false
                }}
                onSubmit={({ isVerified }, actions) => {
                  const controller = new AbortController()
                  const signal = controller.signal
                  const user = {
                    isVerified: String(isVerified) === 'true'
                  }
                  const userId = initialUser.id
                  if (token && userId) {
                    updateUserEmailVerification(userId, token, user, signal)
                  }
                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="emailVerification"
                            className="form-label"
                          >
                            User Verification Status
                          </label>
                          <select
                            aria-label="User Verification Status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={String(values.isVerified)}
                            className={`form-select ${
                              errors.isVerified && touched.isVerified
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="emailVerification"
                            name="isVerified"
                          >
                            <option value="">
                              Select Email Verification Status
                            </option>
                            <option value={'true'}>True</option>
                            <option value={'false'}>False</option>
                          </select>
                          <div
                            id="validationUserEmailVerificationFeedback"
                            className="invalid-feedback"
                          >
                            {errors.isVerified}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary mt-2"
                        disabled={isSubmitting || isLoading}
                      >
                        <i className="bi bi-save"></i> Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="updateUserActiveState"
        tabIndex={-1}
        aria-labelledby="updateUserActiveStateLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="updateUserActiveStateLabel">
                <i className="bi bi-pencil-square me-2"></i>Update User Active
                Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              <p>
                Update the active status for{' '}
                <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                with email<b>{` '${initialUser.email}'`}</b>
              </p>
              <Formik
                validationSchema={userActivationSchema}
                enableReinitialize
                initialValues={{
                  isActive: initialUser.isActive || false
                }}
                onSubmit={({ isActive }, actions) => {
                  const controller = new AbortController()
                  const signal = controller.signal
                  const user = {
                    isActive: String(isActive) === 'true'
                  }
                  const userId = initialUser.id
                  if (token && userId) {
                    updateUserActivation(userId, token, user, signal)
                  }
                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="userActivation"
                            className="form-label"
                          >
                            User Active Status
                          </label>
                          <select
                            aria-label="User Active Status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={String(values.isActive)}
                            className={`form-select ${
                              errors.isActive && touched.isActive
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="userActivation"
                            name="isActive"
                          >
                            <option value="">Select User Active Status</option>
                            <option value={'true'}>True</option>
                            <option value={'false'}>False</option>
                          </select>
                          <div
                            id="validationUserActivationFeedback"
                            className="invalid-feedback"
                          >
                            {errors.isActive}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary mt-2"
                        disabled={isSubmitting || isLoading}
                      >
                        <i className="bi bi-save"></i> Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModalBox
        id={'adminResetPassword'}
        ariaLabelledBy="adminResetPasswordLabel"
        modalHeaderIcon={<i className="bi bi-pencil-square me-2"></i>}
        modalHeader="Reset User Password"
        isLoading={isLoading}
        progress={<Progress marginBottom={false} />}
        modalSubHeader={
          <p>
            {' '}
            Are you sure you want to reset the password for{' '}
            <b>{`'${initialUser.firstName} ${initialUser.lastName}'`}</b> with
            email<b>{` '${initialUser.email}'`}</b>?
          </p>
        }
        onClick={() => updateUserPasswordById(sendEmail)}
        btnClassName="btn btn-primary"
        btnIcon={<i className="bi bi-save"></i>}
        btnText={'Reset'}
        staticBackdrop={true}
      >
        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              onClick={() => setSendEmail(!sendEmail)}
              role="switch"
              id="flexSwitchCheckChecked"
              defaultChecked={sendEmail}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              Send email has been {sendEmail ? 'enabled' : 'disabled'}
            </label>
          </div>
          {userReset && userReset.password && (
            <div className="d-flex flex-row align-items-center pt-2">
              <button
                type="button"
                onClick={() => {
                  navigator?.clipboard.writeText(userReset.password)
                }}
                className="btn btn-outline-primary btn-sm me-2"
              >
                <i className="fa fa-clone" title="copy to clipboard"></i>
              </button>
              <span>
                Password:{' '}
                <span
                  className="fw-bold user-select-all"
                  onClick={() => {
                    navigator?.clipboard.writeText(userReset.password)
                  }}
                >
                  {userReset.password}
                </span>
              </span>
            </div>
          )}
        </div>
      </ConfirmationModalBox>

      <div
        className="modal fade"
        id="editUserModal"
        tabIndex={-1}
        aria-labelledby="editUserLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="editUserLabel">
                <i className="bi bi-pencil-square me-2"></i>Edit User
                Data
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isUpdating && <Progress marginBottom={false} />}
            <div className="modal-body">
              <p>
                <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                with email<b>{` '${initialUser.email}'`}</b>
              </p>
              <Formik
                validationSchema={userSchema}
                enableReinitialize
                initialValues={{
                  salutation: initialUser?.salutation || '',
                  title: initialUser?.title || '',
                  firstName: initialUser?.firstName || '',
                  lastName: initialUser?.lastName || '',
                  birthDate: initialUser?.birthDate ? dayjs(initialUser.birthDate).toDate() : null,
                  hireDate: initialUser?.hireDate ? dayjs(initialUser.hireDate).toDate() : null,
                  releaseDate: initialUser?.releaseDate ? dayjs(initialUser.releaseDate).toDate() : null,
                  startDate: initialUser?.startDate ? dayjs(initialUser.startDate).toDate() : null
                }}
                onSubmit={({ salutation, title, firstName, lastName, birthDate, hireDate, releaseDate, startDate }, actions) => {
                  const controller = new AbortController()
                  const signal = controller.signal
                  const user = {
                    salutation,
                    title,
                    firstName,
                    lastName,
                    birthDate,
                    hireDate,
                    releaseDate,
                    startDate
                  }
                  const userId = initialUser.id
                  if (token && userId) {
                    dispatch(
                      updateUserById({ id: userId, token, user, signal })
                    )
                  }
                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="salutation" className="form-label">
                            Salutation
                          </label>
                          <div className="input-group">
                            <select
                              aria-label="Salutation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.salutation}
                              className={`form-select ${
                                (errors.salutation && touched.salutation) ||
                                error?.errors?.salutation
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              id="salutation"
                            >
                              <option value="">Select Salutation</option>
                              {salutations.map(
                                (salutation: Salutation, index: number) => (
                                  <option key={index}>
                                    {salutation.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div
                            id="validationSalutationFeedback"
                            className="invalid-feedback"
                          >
                            {errors.salutation || error?.errors?.salutation}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <div className="input-group">
                            <select
                              aria-label="Title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                              className={`form-select ${
                                (errors.title && touched.title) ||
                                error?.errors?.title
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              id="title"
                            >
                              <option value="">Select Title</option>
                              {titles.map(
                                (title: Title, index: number) => (
                                  <option key={index}>
                                    {title.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div
                            id="validationTitleFeedback"
                            className="invalid-feedback"
                          >
                            {errors.title || error?.errors?.title}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            type="text"
                            className={`form-control ${
                              (errors.firstName && touched.firstName) ||
                              error?.errors?.firstName
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="firstName"
                            placeholder="Enter first name"
                          />
                          <div
                            id="validationFirstNameFeedback"
                            className="invalid-feedback"
                          >
                            {errors.firstName || error?.errors?.firstName}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            type="text"
                            className={`form-control ${
                              (errors.lastName && touched.lastName) ||
                              error?.errors?.lastName
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="lastName"
                            placeholder="Enter last name"
                          />
                          <div
                            id="validationLastNameFeedback"
                            className="invalid-feedback"
                          >
                            {errors.lastName || error?.errors?.lastName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="birthDate" className="form-label">
                            Birth Date
                          </label>
                          <div
                            className={`${
                              errors.birthDate &&
                                touched.birthDate
                                  ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <Calendar
                              id="birthDate"
                              name="birthDate"
                              onChange={(date: Date) => {
                                setFieldValue('birthDate', date)
                              }}
                              selected={values.birthDate}
                              className={`form-control ${
                                errors.birthDate &&
                                touched.birthDate
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              dateFormat={'dd/MM/yyyy'}
                              autoComplete={'off'}
                              placeholderText="Select birth date"
                              calendarStartDay={DaysOfWeek.Monday}
                            />
                          </div>
                          <div
                            id="validationBirthDateFeedback"
                            className="invalid-feedback"
                          >
                            {errors.birthDate || error?.errors?.birthDate}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="hireDate" className="form-label">
                            Hire Date
                          </label>
                          <div
                            className={`${
                              errors.hireDate &&
                                touched.hireDate
                                  ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <Calendar
                              id="hireDate"
                              name="hireDate"
                              onChange={(date: Date) => {
                                setFieldValue('hireDate', date)
                              }}
                              selected={values.hireDate}
                              className={`form-control ${
                                errors.hireDate &&
                                touched.hireDate
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              dateFormat={'dd/MM/yyyy'}
                              autoComplete={'off'}
                              placeholderText="Select hire date"
                              calendarStartDay={DaysOfWeek.Monday}
                            />
                          </div>
                          <div
                            id="validationHireDateFeedback"
                            className="invalid-feedback"
                          >
                            {errors.hireDate || error?.errors?.hireDate}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="startDate" className="form-label">
                            Start Date
                          </label>
                          <div
                            className={`${
                              errors.startDate &&
                                touched.startDate
                                  ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <Calendar
                              id="startDate"
                              name="startDate"
                              onChange={(date: Date) => {
                                setFieldValue('startDate', date)
                              }}
                              selected={values.startDate}
                              className={`form-control ${
                                errors.startDate &&
                                touched.startDate
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              dateFormat={'dd/MM/yyyy'}
                              autoComplete={'off'}
                              placeholderText="Select start date"
                              calendarStartDay={DaysOfWeek.Monday}
                            />
                          </div>
                          <div
                            id="validationStartDateFeedback"
                            className="invalid-feedback"
                          >
                            {errors.startDate || error?.errors?.startDate}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="releaseDate" className="form-label">
                            Release Date
                          </label>
                          <div
                            className={`${
                              errors.releaseDate &&
                                touched.releaseDate
                                  ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <Calendar
                              id="releaseDate"
                              name="releaseDate"
                              onChange={(date: Date) => {
                                setFieldValue('releaseDate', date)
                              }}
                              selected={values.releaseDate}
                              className={`form-control ${
                                errors.releaseDate &&
                                touched.releaseDate
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              dateFormat={'dd/MM/yyyy'}
                              autoComplete={'off'}
                              placeholderText="Select release date"
                              calendarStartDay={DaysOfWeek.Monday}
                            />
                          </div>
                          <div
                            id="validationReleaseDateFeedback"
                            className="invalid-feedback"
                          >
                            {errors.releaseDate || error?.errors?.releaseDate}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary mt-2"
                        disabled={isSubmitting || isUpdating}
                      >
                        <i className="bi bi-save"></i> Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editUserAddressModal"
        tabIndex={-1}
        aria-labelledby="editUserAddressLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="editUserAddressLabel">
                <i className="bi bi-pencil-square me-2"></i>Edit User
                Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isUpdating && <Progress marginBottom={false}/>}
            <div className="modal-body">
              <p>
                <b>{` '${initialUser.firstName} ${initialUser.lastName}' `}</b>
                with email<b>{` '${initialUser.email}'`}</b>
              </p>
              <div>
                <AddressEditor
                  addresses={initialUser.addresses || []}
                  userId={String(initialUser?.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="userModal"
        tabIndex={-1}
        aria-labelledby="userLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="userLabel">
                <i className="bi bi-pencil-square me-2"></i>Create A User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              <Formik
                validationSchema={adminUserCreateSchema}
                enableReinitialize
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  password: '',
                  confirmPassword: '',
                  role: '',
                  companyId: null,
                  isGhost: false
                }}
                onSubmit={(
                  {
                    firstName,
                    lastName,
                    email,
                    password,
                    role,
                    companyId,
                    isGhost
                  },
                  actions
                ) => {
                  const controller = new AbortController()
                  const signal = controller.signal
                  const user = {
                    firstName,
                    lastName,
                    email,
                    password,
                    role,
                    companyId: companyId || null,
                    isGhost
                  }

                  if (token) {
                    dispatch(createUser({ token, user, signal }))
                  }
                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="adminFirstName"
                            className="form-label"
                          >
                            First Name
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            type="text"
                            className={`form-control ${
                              (errors.firstName && touched.firstName) ||
                              error?.errors?.firstName
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="adminFirstName"
                            name="firstName"
                            placeholder="Enter first name"
                          />
                          <div
                            id="validationAdminFirstNameFeedback"
                            className="invalid-feedback"
                          >
                            {errors.firstName || error?.errors?.firstName}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="adminLastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            type="text"
                            className={`form-control ${
                              (errors.lastName && touched.lastName) ||
                              error?.errors?.lastName
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="adminLastName"
                            name="lastName"
                            placeholder="Enter last name"
                          />
                          <div
                            id="validationLastNameFeedback"
                            className="invalid-feedback"
                          >
                            {errors.lastName || error?.errors?.lastName}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="adminEmail" className="form-label">
                            Email
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            type="text"
                            className={`form-control ${
                              (errors.email && touched.email) ||
                              error?.errors?.message === 'email must be unique'
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="adminEmail"
                            name="email"
                            placeholder="Enter first name"
                            autoComplete="off"
                          />
                          <div
                            id="validationAdminEmailFeedback"
                            className="invalid-feedback"
                          >
                            {errors.email ||
                              error?.errors?.email ||
                              (error?.errors?.message === 'email must be unique'
                                ? error?.errors?.message
                                : '')}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="adminPassword" className="form-label">
                            Password
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            type="password"
                            className={`form-control ${
                              (errors.password && touched.password) ||
                              error?.errors?.password
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="adminPassword"
                            name="password"
                            placeholder="Enter Password"
                          />
                          <div
                            id="validationAdminPasswordFeedback"
                            className="invalid-feedback"
                          >
                            {errors.password || error?.errors?.password}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="adminConfirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            type="password"
                            className={`form-control ${
                              errors.confirmPassword && touched.confirmPassword
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="adminConfirmPassword"
                            name="confirmPassword"
                            placeholder="Enter Confirm Password"
                          />
                          <div
                            id="validationAdminConfirmPasswordFeedback"
                            className="invalid-feedback"
                          >
                            {errors.confirmPassword}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="userRoleAdmin" className="form-label">
                            User Role
                          </label>
                          <select
                            aria-label="User Role"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.role}
                            className={`form-select ${
                              (errors.role && touched.role) ||
                              error?.errors?.role
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="userRoleAdmin"
                            name="role"
                          >
                            <option value="">Select Role</option>
                            <option value={userRoles.ADMIN}>{userRoles.ADMIN}</option>
                            {editableRoles.map((role) => (
                              <option key={role.value} value={role.value}>
                                {role.text}
                              </option>
                            ))}
                          </select>
                          <div
                            id="validationAdminUserRoleFeedback"
                            className="invalid-feedback"
                          >
                            {errors.role}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="company" className="form-label">
                            Company
                          </label>
                          <Select
                            className={`${
                              errors.companyId &&
                              touched.companyId &&
                              errors.companyId
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  errors.companyId && touched.companyId
                                    ? '#dc3545'
                                    : provided.borderColor,
                                '&:hover': {
                                  borderColor:
                                    errors.companyId && touched.companyId
                                      ? '#dc3545'
                                      : provided.borderColor
                                }
                              })
                            }}
                            isClearable
                            inputId="company"
                            name="companyId"
                            aria-label="Company"
                            options={companies}
                            getOptionLabel={(company) =>
                              `${company.name} - ${
                                company.domain || 'Domain not set'
                              }`
                            }
                            getOptionValue={(company) => String(company.id)}
                            onChange={(selectedOption) => {
                              const selectedCompanyId =
                                selectedOption?.id ?? ''
                              setFieldValue('companyId', selectedCompanyId)
                            }}
                            onBlur={handleBlur}
                            isLoading={isLoadingCompanies}
                            value={companies.find(
                              (company) => company.id === values.companyId
                            )}
                          />
                          <div
                            id="validationCompanyFeedback"
                            className="invalid-feedback"
                          >
                            {errors.companyId}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="ghostStatus" className="form-label">
                            Ghost Status
                          </label>
                          <select
                            aria-label="Ghost Status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={String(values.isGhost)}
                            className={`form-select ${
                              errors.isGhost && touched.isGhost
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="ghostStatus"
                            name="isGhost"
                          >
                            <option value="">Select Ghost Status</option>
                            <option value={'true'}>True</option>
                            <option value={'false'}>False</option>
                          </select>
                          <div
                            id="validationGhostStatusFeedback"
                            className="invalid-feedback"
                          >
                            {errors.isGhost}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary mt-2"
                        disabled={isSubmitting || isLoading}
                      >
                        <i className="bi bi-save"></i> Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isLoading={isLoading}
        deleteById={deleteUserById}
        id={String(initialUser?.id)}
        name={
          <>
            <span className="fw-bold">{`'${initialUser?.email}'`}</span> user?
          </>
        }
        token={String(token)}
        autoDismiss={false}
      />
      <ModalBox modalHeader="Export Users To CSV" id="exportToCSV">
        <Formik
          initialValues={{
            export: ''
          }}
          onSubmit={async (values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <input
                    type="radio"
                    name="export"
                    value="currentPage"
                    checked={exportType === 'currentPage'}
                    className="form-check-input"
                    onChange={(event) => {
                      handleChange(event.target.value)
                      setFieldValue('export', event.target.value)
                      setExportType('currentPage')
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">Current Page</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="export"
                    value="allPages"
                    className="form-check-input"
                    checked={exportType === 'allPages'}
                    onChange={(event) => {
                      handleChange(event.target.value)
                      setFieldValue('export', event.target.value)
                      setExportType('allPages')
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">All Pages</span>
                </label>
              </div>
            </form>
          )}
        </Formik>
        <div className="mt-4 text-end">
          {exportType !== 'allPages'
            ? (
              <CSVDownloader
                btnText="Export"
                data={exportData}
                fileName={`Users export ${currentDate.format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  dispatch(getAllPagesForUsers(true))
                  dismissModal('exportToCSV')
                }}
              >
                Export
              </button>
              )}
        </div>
      </ModalBox>
    </main>
  )
}

export default AllUsers
