import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Select from 'react-select'
import { countries } from '../../utils/countries'
import { Address } from '../../types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { updateUserAddress } from '../../store/reducers/api/usersReducer'
import { addAddressToEmployee } from '../../store/reducers/api/companyReducer'
import { phoneValidationPattern } from '../../constants/regexPatterns'

const AddressEditor = ({
  addresses,
  userId,
  companyId
}: {
  addresses: Address[];
  userId: string;
  companyId?: string;
}) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiUsers.isUpdating)
  const error = useAppSelector((state) => state.profile.error)

  const [initialAddress, setInitialAddress] = useState<Address | null>(null)
  const countryOptions = countries.map(country => ({ value: country }))

  const dispatch = useAppDispatch()

  const token = currentUser?.token
  const types = [{ type: 'billing', label: 'Billing' }, { type: 'delivery', label: 'Delivery' }, { type: 'billingAndDelivery', label: 'Billing and Delivery' }]

  const addressSchema = object({
    country: string().required('Country is required').oneOf(countries),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string()
      .required('Zip is required')
      .max(16, 'Zip is too long'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    addressAddition: string()
      .nullable()
      .max(255, 'Address Addition is too long'),
    type: string().oneOf(types.map(type => type.type)).nullable()
  })

  const getAddressByType = (type: string | null) => {
    const address = addresses?.find((address) => address.type === type || address.type === null)
    return address || null
  }

  useEffect(() => {
    setInitialAddress(getAddressByType('delivery'))
  }, [addresses])

  return (
    <div>
      <Formik
        validationSchema={addressSchema}
        enableReinitialize
        initialValues={{
          country: initialAddress?.country || '',
          city: initialAddress?.city || '',
          street: initialAddress?.street || '',
          zip: initialAddress?.zip || '',
          phone: initialAddress?.phone || '',
          addressAddition: initialAddress?.addressAddition || '',
          type: initialAddress?.type || ''
        }}
        onSubmit={(
          { country, city, street, zip, phone, addressAddition, type },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const address = {
            id: initialAddress?.id || null,
            country,
            city,
            street,
            zip,
            phone,
            addressAddition,
            type: type || null,
            affiliation: companyId ? 'company' : 'personal'
          }
          if (token && userId) {
            if (companyId) {
              dispatch(
                addAddressToEmployee({
                  companyId,
                  userId,
                  token,
                  address,
                  signal
                })
              )
            } else {
              dispatch(
                updateUserAddress({
                  id: userId,
                  token,
                  address,
                  signal
                })
              )
            }
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileStreet" className="form-label">
                    Street and House Number
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                    type="text"
                    className={`form-control ${
                      (errors.street && touched.street) || error?.errors?.street
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileStreet"
                    name="street"
                    placeholder="Enter street and House Number"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileStreetFeedback"
                    className="invalid-feedback"
                  >
                    {errors.street || error?.errors?.street}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileZip" className="form-label">
                    Zip
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                    type="text"
                    className={`form-control ${
                      (errors.zip && touched.zip) || error?.errors?.zip
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileZip"
                    name="zip"
                    placeholder="Enter zip"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileZipFeedback"
                    className="invalid-feedback"
                  >
                    {errors.zip || error?.errors?.zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileCity" className="form-label">
                    City
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className={`form-control ${
                      (errors.city && touched.city) || error?.errors?.city
                        ? 'is-invalid'
                        : ''
                    }`}
                    value={values.city}
                    id="profileCity"
                    name="city"
                    placeholder="Enter city"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileCityFeedback"
                    className="invalid-feedback"
                  >
                    {errors.city || error?.errors?.city}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileAddressAddition" className="form-label">
                    Address Addition
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.addressAddition}
                    type="text"
                    className={`form-control ${
                      (errors.addressAddition &&
                      touched.addressAddition) || error?.errors?.addressAddition
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileAddressAddition"
                    name="addressAddition"
                    placeholder="Enter Address Addition"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileAddressAdditionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.addressAddition || error?.errors?.addressAddition}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profilePhone" className="form-label">
                    Phone
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    type="text"
                    className={`form-control ${
                      (errors.phone && touched.phone) || error?.errors?.phone
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profilePhone"
                    name="phone"
                    placeholder="Enter Phone Number"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfilePhoneFeedback"
                    className="invalid-feedback"
                  >
                    {errors.phone || error?.errors?.phone}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileCountry" className="form-label">
                    Country
                  </label>
                  <Select
                    className={`${
                      touched.country && errors.country ? 'is-invalid' : ''
                    }`}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor:
                          errors.country && touched.country ? '#dc3545' : provided.borderColor,
                        '&:hover': {
                          borderColor:
                            errors.country && touched.country ? '#dc3545' : provided.borderColor
                        }
                      })
                    }}
                    isClearable
                    inputId="profileCountry"
                    name="country"
                    aria-label="Country"
                    options={countryOptions}
                    getOptionLabel={(country: { value: string }) => `${country.value}`}
                    getOptionValue={(country: { value: string }) => String(country.value)}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: 'country',
                          value: selectedOption ? selectedOption.value : ''
                        }
                      })
                    }}
                    onBlur={handleBlur}
                    value={countryOptions.find((option) => option.value === values.country)}
                  />
                  <div id="validationProfileCountryFeedback" className="invalid-feedback">
                    {errors.country || error?.errors?.country}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileType" className="form-label">
                    Type
                  </label>
                  <select
                    aria-label="Type"
                    onChange={(event) => {
                      handleChange(event)
                      setInitialAddress(getAddressByType(event.target.value || null))
                    }}
                    onBlur={handleBlur}
                    value={values.type}
                    className={`form-select ${
                      (errors.type && touched.type)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileType"
                    name="type"
                    autoComplete="off"
                  >
                    <option value="">Select Type</option>
                    {types.map((type, index: number) => (
                      <option key={index} value={type.type}>{type.label}</option>
                    ))}
                  </select>
                  <div
                    id="validationProfileCountryFeedback"
                    className="invalid-feedback"
                  >
                    {errors.type}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isUpdating || isSubmitting}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default AddressEditor
