import axios from 'axios'
import { AccessPermission, Company, User } from '../../types'
import { CAMPAIGNMANAGER, COMPANYADMINISTRATOR, EMPLOYEE } from '../../constants/userRoles'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  createACompany (token: string, company: Partial<Company>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/companies', { company }, config)
  },

  getCompanies (token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/companies', config)
  },

  getCompanyById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}`, config)
  },

  updateCompanyById (id: string, token: string, company: Partial<Company>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/companies/${id}`, { company }, config)
  },

  deleteCompanyById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/companies/${id}`, config)
  },

  addCompanyAddress (id: string, token: string, address: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${id}/addresses`, { address }, config)
  },

  addCompanyCampaign (id: string, token: string, campaign: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${id}/campaigns`, { campaign }, config)
  },

  getCompanyCampaigns (id: string, token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/campaigns`, config)
  },

  addCompanyCostCenter (id: string, token: string, costCenter: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${id}/cost-centers`, { costCenter }, config)
  },

  getCompanyCostCenters (id: string, token: string, perPage: number, page: number, inputValue: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page,
        search: inputValue
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/cost-centers`, config)
  },

  addCompanyLegalText (id: string, token: string, legalText: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${id}/legal-texts`, { legalText }, config)
  },

  getCompanyLegalTexts (id: string, token: string, perPage: number, page: number, filter: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/legal-texts?${filter}`, config)
  },

  addCompanyProduct (id: string, token: string, product: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${id}/products`, { product }, config)
  },

  getCompanyProducts (id: string, token: string, perPage: number, page: number, search: string, signal: AbortSignal, orderBy: string = '', filter: string = '') {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/products?${orderBy}&${filter}`, config)
  },

  addCompanyUser (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${id}/users`, { user }, config)
  },

  getCompanyUsers (id: string, token: string, perPage: number, page: number, signal: AbortSignal, filter?: string) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    let url = `/companies/${id}/users`
    if (filter) {
      url += `?${filter}`
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(url, config)
  },
  async getCompanyTotalUsers (id: string, token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    let page = 1
    let pageCount = 2
    const accumulated: Array<User> = []
    do {
      const { data } = await apiClient.get(`/companies/${id}/users?limit=${100}&page=${page}`, signal)
      accumulated.push(...data.users) // Concatenate the results to accumulated array
      pageCount = data.meta.pageCount
      page++
    } while (page <= pageCount)
    return accumulated
  },
  updateRoleOfEmployee (companyId: string, userId: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${companyId}/users/${userId}`, { user }, config)
  },

  updateEmployeeEmailVerification (companyId: string, userId: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${companyId}/users/${userId}/email-verification`, { user }, config)
  },

  updateDataOfEmployee (companyId: string, userId: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/companies/${companyId}/users/${userId}`, { user }, config)
  },

  addAddressToEmployee (companyId: string, userId: string, token: string, address: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${companyId}/users/${userId}/address`, { address }, config)
  },

  requestDomainVerification (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/request-domain-verification`, config)
  },

  verifyDomain (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/verify-domain`, config)
  },

  updateCompanyDomainVerificationStatus (id: string, token: string, company: { isDomainVerified: boolean }, signal: AbortSignal) {
    const config = {
      signal
    }

    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${id}/verify-domain`, { company }, config)
  },

  getCompanyAddresses (id: string, token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/addresses`, config)
  },

  getCompanyInviteLink (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/invite-link`, config)
  },

  getCompanyAccessPermissions (id: string, token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/companies/${id}/access-permissions`, config)
  },

  addCompanyAccessPermission (id: string, token: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/companies/${id}/access-permissions`, { accessPermission }, config)
  },
  updateCompanyInviteLink (id: string, token: string, companyInviteToken: { roles: string[] }, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${id}/invite-link`, { companyInviteToken }, config)
  },
  updateCompanyInviteDomainCheck (id: string, token: string, companyInviteToken: { roles: { [EMPLOYEE]: boolean, [CAMPAIGNMANAGER]: boolean, [COMPANYADMINISTRATOR]: boolean } }, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${id}/invite-domain-check`, { companyInviteToken }, config)
  },
  uploadCompanyLogo (token: string, id: string, company: Pick<Company, 'logo'>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${id}/logo`, { company }, { config }
    )
  },
  updateCompanyTheme (token: string, id: string, company: Pick<Company, 'theme'>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/companies/${id}/theme`, { company }, { config }
    )
  }
}
