import React from 'react'
import { useAppDispatch } from '../../store/hooks'
import Progress from '../loaders/Progress'

interface ConfirmationModalProps {
  isLoading: boolean
  deleteById: Function
  id: string
  name: string | any
  token: string
  buttonTitle?: 'Delete' | 'Remove'
  modalName?: string
  autoDismiss?: boolean
}

const DeleteConfirmationModal = ({ isLoading, deleteById, id, name, token, buttonTitle = 'Delete', modalName = 'confirmationModal', autoDismiss = true }: ConfirmationModalProps) => {
  const dispatch = useAppDispatch()

  return (
    <div className="modal fade" id={modalName} tabIndex={-1} aria-labelledby={`${modalName}Label`} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title text-danger" id={`${modalName}Label`}>
              <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {isLoading && <Progress marginBottom={false} />}
          <div className="modal-body">
            <p>
              {`Are you sure you want to ${buttonTitle.toLowerCase()} the `}{name}
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (token && id !== null) {
                  const controller = new AbortController()
                  const signal = controller.signal
                  dispatch(deleteById({ id, token, signal }))
                }
              }}
              disabled={isLoading}
              data-bs-dismiss={autoDismiss ? 'modal' : ''}
              aria-label={buttonTitle}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteConfirmationModal
